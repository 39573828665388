body, html {
  overflow-x: hidden;
  }
::-webkit-scrollbar{
  width: 5px;
}
::-webkit-scrollbar-track{
  background-color: transparent;
}
::-webkit-scrollbar-thumb{
  scrollbar-width: thin;  
  background-color: #000;
  border-radius: 20px;
  box-shadow: #000;
}
span {
  position: relative; /* Allow the spans to move relative to their original position */
  display: inline-block; /* Treat the spans like block elements so they can move */
}
.cormorant-upright-light {
  font-family: "Cormorant Upright", serif;
  font-weight: 300;
  font-style: normal;
}

.cormorant-upright-regular {
  font-family: "Cormorant Upright", serif;
  font-weight: 400;
  font-style: normal;
}

.cormorant-upright-medium {
  font-family: "Cormorant Upright", serif;
  font-weight: 500;
  font-style: normal;
}

.cormorant-upright-semibold {
  font-family: "Cormorant Upright", serif;
  font-weight: 600;
  font-style: normal;
}

.cormorant-upright-bold {
  font-family: "Cormorant Upright", serif;
  font-weight: 700;
  font-style: normal;
}