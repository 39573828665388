.mainheight{
    height: 100vh;
}
.cloud{
    position: absolute;
    height: 300px;
    z-index: 1;
}
h1{
    font-size: 50px;
}
#laptop{
    z-index: 0;
}
.home{
    background-image: url("../images/bgmain.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: left;
}