.bgstory{
    background-image: url("../images/blackbg.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: left;
}
.bgwhitem{
    background-image: url("../images/bgwhitem.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: left;
}