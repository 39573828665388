canvas{
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}
@media only screen and (max-width: 600px) {
    .canvasdisplay{
    display: none;
    }
  }